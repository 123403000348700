import React from "react"
import {Link, graphql} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../components/layout"
import SEO from "../components/seo"
import LayoutInner from "../components/layoutInner";
import * as PATHS from "../utils/paths";

const styles = theme => ({
    root: {},
    heroText: {
        fontSize: 32,
        lineHeight: "48px",
        fontWeight: 400,
        marginBottom: 16
    },
    subheroText: {
        fontSize: 18,
        lineHeight: "32px",
        fontWeight: "regular",
        marginBottom: 16
    },
});

const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title={"About 3iap"} location={{pathname: PATHS.ABOUT_PAGE}}/>

            <LayoutInner>

                <h1>About 3iap</h1>
                <p>
                    3iap believes in the power of reason as a force for good.
                    When faced with uncertainty or a tough decision, wouldn't it be great if more people first asked,
                    “What does the data say?”
                </p>
                <p>
                    The challenge: People can be a <i>bit</i> irrational.
                    Our attention is under constant strain.
                    Even when we have good data, we often lack the confidence to use it.
                    We're even more skeptical when it conflicts with our own intuition.
                </p>
                <p>
                    By making clients' data more approachable,
                    3iap enables more positive interactions with data, ultimately leading to
                    smarter decisions and improved performance.
                    In turn, we hope that these positive experiences help clients' users
                    learn to trust and rely on data in their own lives.
                </p>
                <br/>
                <h2>Who is behind 3 is a pattern?</h2>
                <p>
                    3iap's founder is Eli Holder.
                    Eli's research and writing focus on the intersection of social psychology and visualization design, looking at ways that data can both reflect and influence people's attitudes, beliefs, emotions, and behaviors.
                    His research on equitable data visualization was the first to empirically show how conventional visualizations of social inequality can reinforce harmful stereotypes.
                    His research, writing, and designs have been published or featured in IEEE VIS, the Data Visualization Society's Nightingale magazine, as well as online publishers like Boing Boing, Data is Plural, and TechCrunch.
                    Eli is also a regular contributor to the Data Visualization Society's Nightingale blog, as well as Effect Affect, a newsletter covering the science and social impact of visualization design.
                </p>
                <p>
                    Eli is a former founder (Unblab, acquired by AOL, Notch.me, Nodd) and product leader (Noom, Chartbeat, PwC, StreetLinx).
                    He graduated with a B.S. in computer science from UNC Chapel Hill, where he studied board game AI and built 3D-visualizations for microscopes.
                </p>
                <p>
                    You can follow Eli on <a href={"https://bsky.app/profile/elibryan.bsky.social"} target={"_blank"}>Bluesky</a> or <a href={"https://medium.com/@elibryan"} target={"_blank"}>Medium</a>.
                </p>


            </LayoutInner>

        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
